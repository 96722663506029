export const environment = {
  env: 'helperapp',
  name: 'HelperApp',
  production: false,
  firebase: {
    apiKey: 'AIzaSyCI1ZtYEz3myRupKXwkgZ_f-pLthoC752k',
    authDomain: 'hallo-bot.firebaseapp.com',
    databaseURL: 'https://hallo-bot.firebaseio.com',
    projectId: 'hallo-bot',
    storageBucket: 'hallo-bot.appspot.com',
    messagingSenderId: '82103981851',
    appId: '1:82103981851:web:8cc527dbf1280df8b025be',
  },
  loginMethod: {
    phone: true,
    email: false,
  },
  background: {
    profile:
      'https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/Background%2FHelperApp_ProfielBanner_130120-01.png?alt=media&token=cb1797ba-119c-465f-802c-be025d98b6c4',
  },
  login: {
    video:
      'https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/Background%2FHelperApp_Banner.mp4?alt=media&token=01c88265-dd41-48dc-86df-dca8f2d63cf6',
    image:
      'https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/logo%2Fhelper%20icon.png?alt=media&token=06265c16-613b-4459-95d6-80241ee4e6ce',
  },
  phone: {
    nl: '+316',
    us: '+1',
  },
  routes: ['login', 'profile'],
};
